import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import sampleImage from "../images/sample.jpg";
import sampleBlurredImage from "../images/blurred_sample.jpg";
import arrowRight from "../images/arrow_right.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={['blurry', 'photo', 'social', 'media', 'artificial', 'intelligence', 'NLP', 'face', 'detection']}
      />

      <section className="text-center"> 
        <p className="leading-loose align-center">
          Blurry is a AI-powered privacy tool that makes it east to censor social media screenshots of names and faces.
        </p>
      </section>

      <br></br>
      <br></br>
      <br></br>

      <div class="flex mb-3">
        <div class="w-1/2 h-auto">
          <img class="block" src={sampleImage} className="block mx-auto w-1/1" alt="Sample upload"/>
        </div>

        <div class="w-1/2 f p-20">
          <img class="block" src={arrowRight} className="block mx-auto w-1/1" alt="Sample upload" width="200" height="25"/>
        </div>

        <div class="w-1/2 h-auto">
          <img class="block" src={sampleBlurredImage} className="block mx-auto w-1/1" alt="Blurred sample upload"/>
        </div>
      </div>


      <section className="text-center">
        <form action="/upload" method="post">
          <button className="border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white">
            Get started
          </button>
          </form>


        <p className="leading-loose">
         Blurry is currently under construction, but will be up soon!
        </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
