import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Header from "./header";

function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </main>

      <footer className="bg-blue-700" >
        <nav className="flex justify-between max-w-3xl mx-auto p-4 md:p-8 text-sm">
          <p className="text-white">
            Created by Brian
          </p>

          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
          <input type="hidden" name="cmd" value="_donations" />
          <input type="hidden" name="business" value="joseph.b@husky.neu.edu" />
          <input type="hidden" name="currency_code" value="USD" />
          <button className="border-b-4 border-gray-800 hover:border-gray-700 bg-red-700 hover:bg-red-600 font-bold px-4 py-2 rounded text-sm text-white">
            Donate
          </button>
          </form>

          <p>
            <a
              href="https://github.com/FermenFlo"
              className="font-bold no-underline text-white"
            >
              GitHub
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
